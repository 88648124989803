import React from 'react'
import { graphql, Link } from 'gatsby'
import TitlePage from '../components/TitlePage'
import SEO from '../components/seo'
import * as S from '../components/Content/styled'
import * as L from '../components/SensorsEquipments/styled'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import '../components/CSS/slick.css'
import useTranslations from '../components/useTranslations'
import DocumentDownload from '../components/LandingPage/documentDownload'
import LocalizedLink from '../components/LocalizedLink'

const Sensor = props => {
  const post = props.data.markdownRemark
  const image = post.frontmatter.featuredImage.childImageSharp.fluid
  const secondImage = post.frontmatter.featuredSecondImage.childImageSharp.fluid

  //Image SRC for Card Image
  const imageSRC = post.frontmatter.featuredImage.childImageSharp.fluid.src
  //const secondImageSRC = post.frontmatter.featuredImage.childImageSharp.fluid.src

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
  }
  const { retmonTitle, navbar, back } = useTranslations()

  return (
    <>

      <SEO
        title={`${post.frontmatter.title} | ${retmonTitle}`}
        description={post.excerpt}
        image={imageSRC}
        image={
          post.frontmatter.cardImage ?
            post.frontmatter.cardImage :
            'https://www.retmon.com' + imageSRC}
      />
      <L.TitleWrapper>
        <LocalizedLink to={`/${navbar.productsSub.dataCollectionURL}/`}>
          {back}
        </LocalizedLink>
        <TitlePage text={post.frontmatter.subTitle} />
        <L.SubHeader>{post.frontmatter.title}</L.SubHeader>
      </L.TitleWrapper>
      <Slider {...settings}>
        {post.frontmatter.verticalImage === true ? (
          <div>
            <L.TerminalVerticalImage
              fluid={image}
              alt={post.frontmatter.title}
            />
          </div>
        ) : (
            <div>
              <L.TerminalImage fluid={image} alt={post.frontmatter.title} />
            </div>
          )}
        {post.frontmatter.singleImage === true ? null : (
          <div>
            <L.TerminalImage fluid={secondImage} alt={post.frontmatter.title} />
          </div>
        )}
      </Slider>
      <S.Content>
        <div dangerouslySetInnerHTML={{ __html: post.html }}></div>
      </S.Content>
    </>
  )
}

export const query = graphql`
  query DataCollection($locale: String!, $title: String!) {
    markdownRemark(
      frontmatter: { title: { eq: $title } }
      fields: { locale: { eq: $locale } }
    ) {
      frontmatter {
        title
        subTitle
        singleImage
        order
        verticalImage
        cardImage
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              aspectRatio
              src
              srcSet
              sizes
            }
          }
        }
        featuredSecondImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              aspectRatio
              src
              srcSet
              sizes
            }
          }
        }
        page
        date
        subURL
      }
      excerpt(pruneLength: 150)
      html
    }
  }
`
export default Sensor
