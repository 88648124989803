import styled from 'styled-components';
import media from 'styled-media-query';
import Img from 'gatsby-image';

export const Container = styled.div`

`
export const RowStart = styled.div`
display:flex;
flex-wrap: wrap;
`
export const Column = styled.div`
width:100%
`
export const Margin = styled.div`
margin-top:5rem;
margin-bottom:5rem;
${media.lessThan('medium')`
margin: 0;
`}
`

export const MarginBlog = styled.div`
margin-top:5rem;
margin-bottom:5rem;
${media.lessThan('medium')`
margin: 2rem;
`}
`;

export const BorderedColumn = styled.div`
border: 1px solid rgba(109,94,94,0.58039);
border-radius: 1rem;
background: white;  
padding: 2rem;
width:100%;

display: flex;
flex-wrap: wrap;
`

export const Header = styled.h1`
font-size: ${props => props.Size || "32px"};
font-weight: bold
`
export const SubHeader = styled.h2`
font-size: ${props => props.Size || "2rem"};
color:  ${props => props.Color || "var(--info)"};
font-weight:100;
margin-bottom:2rem;
`
export const Text = styled.p`
font-size: ${props => props.Size || "16px"};
`

export const DividerForRow = styled.div`
margin-top: ${props => props.Top || "5em"};
margin-bottom: ${props => props.Bottom || "5em"};
`

export const RetmonCTAButton = styled.button`
background: ${props => props.Color || "var(--red)"};
color:white;
padding:9px;
width:10rem;
transition: all .3s ease; 
&:hover{
background: #ec0753;
transition: all .3s ease; 
  }
&:active,
&:focus{
background: var(--redGR);
  } 
`
export const Span = styled.span`
color: ${props => props.Color || "var(--red)"};
`

export const FlexCardImages = styled.div`
margin-top:2rem;
margin-bottom:2rem;
display: flex;
flex-wrap: wrap;
justify-content: space-between;
`
export const CardImages = styled(Img)`
margin:2rem;
&:hover{
  transform: scale3d(1.04, 1.04, 1.04);
  transition: transform .2s ease-in;
  } 
  ${media.lessThan('medium')`
margin-bottom: 2rem;
`}
`
export const SensorImage = styled(Img)`
width:45rem;
margin:auto;
${media.lessThan('medium')`
width: auto;
`}
${media.lessThan('small')`
width: auto;
`}
`
export const SensorCloudinaryImage = styled.img`
width:45rem;
margin:auto;
${media.lessThan('medium')`
width: auto;
`}
${media.lessThan('small')`
width: auto;
`}
`
export const BlogImage = styled(Img)`
width: auto;
display:block;
margin: auto;
margin-top: 3rem;
${media.lessThan('medium')`
width: auto;
margin-top: 3rem;
`}
${media.lessThan('small')`
width: auto;
margin-top: 3rem;
`}
`
export const BlogImageURL = styled.img`
width: auto;
display:block;
margin: auto;
margin-top: 3rem;
${media.lessThan('medium')`
width: auto;
margin-top: 3rem;
`}
${media.lessThan('small')`
width: auto;
margin-top: 3rem;
`}
`
export const TerminalImage = styled(Img)`
width:50%;
margin:auto;
${media.lessThan('medium')`
width: auto;
margin: 1rem;
`}
`
export const TerminalVerticalImage = styled(Img)`
width: 20%;
margin:auto;
${media.lessThan('medium')`
width: 12rem;
`}
`

export const CardImageWrapper = styled.div`
flex: 0 0 33.333333%;
max-width: 33.333333%;
position: relative;
width: 100%;
margin: auto;

text-align: -webkit-center;
`

export const TitleWrapper = styled.div`
margin-top:1rem;
${media.lessThan('medium')`
margin-top:0rem;
`}
`
