import React from "react"
import RetmonPDF from "../../images/icons/retmon-pdf.svg"
import useTranslations from '../../components/useTranslations';
import * as S from "./styled"
import { LocaleContext } from '../Layout';

const DocumentDownlad = ({ data }) => {
    const { locale } = React.useContext(LocaleContext);
    const { landingPage } = useTranslations();

    return (<>
        <S.DocumentCol>
            {locale === 'tr' ? 
            < a href='/docs/retmon-katalog-2020.pdf'>
                <S.SVG Size='5rem' style={{ marginBottom: '1rem' }} src={RetmonPDF} alt='Retmon PDF'></S.SVG>
                <S.Text>{landingPage.document}</S.Text>
            </a>
            :
            < a href='/docs/retmon-catalog-2020-en.pdf'>
                <S.SVG Size='5rem' style={{ marginBottom: '1rem' }} src={RetmonPDF} alt='Retmon PDF'></S.SVG>
                <S.Text>{landingPage.document}</S.Text>
            </a>
            }
        </S.DocumentCol>
        {/* <S.WhatsappCol>
            <a href='https://api.whatsapp.com/send?phone=905531282587&amp;text=Merhaba,%20Retmon%20çözümleri%20hakkında%20bilgi%20almak%20istiyorum.' target="_blank" rel="noopener" >
                <S.WhatsappIcon></S.WhatsappIcon>
            </a>
            <S.Text>{landingPage.support}</S.Text>
        </S.WhatsappCol> */}
    </>
    )
};


export default DocumentDownlad 