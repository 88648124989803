import styled from 'styled-components';
import media from 'styled-media-query';

export const TitleElement = styled.h1`
margin-top: 1rem;
  font-size: 3rem;
  font-weight: bold;

  ${media.lessThan('medium')`
    line-height: 1.1;
    font-size: 2rem;
  `}
`;
